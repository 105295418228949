import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"background": "--color-light",
	"quarkly-title": "Cards-1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"align-self": "center",
			"position": "relative",
			"md-align-self": "center",
			"md-display": "flex",
			"md-justify-content": "center",
			"z-index": "1"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://nexialux.com/img/5.jpg",
			"display": "block",
			"width": "500px",
			"height": "500px",
			"transform": "rotateZ(2deg)",
			"border-radius": "8px",
			"md-width": "90%",
			"md-height": "90%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"position": "relative",
			"margin": "20px 0px 0 0px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"z-index": "2"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"text-align": "center",
			"font": "normal 400 44px/1.2 --fontFamily-sansHelvetica",
			"width": "900px",
			"md-position": "static",
			"md-width": "auto",
			"sm-font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"children": "Приєднуйтесь до пригоди вже сьогодні!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 400 28px/1.2 --fontFamily-sans",
			"width": "650px",
			"md-width": "auto",
			"sm-font": "normal 400 36px/1.2 --fontFamily-sans",
			"children": "Не пропустіть найкращий пейнтбольний досвід на Dvrz Paintball. Збирайте друзів, екіпіруйтеся та готуйтеся до незабутнього дня екшену та азарту. Ми з нетерпінням чекаємо на вас на полі та побачимось з вами у запалі бою."
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"position": "static",
			"margin": "40px 0px 0px 0px",
			"background": "--color-dark",
			"border-radius": "50px",
			"padding": "14px 35px 14px 35px",
			"font": "--base",
			"text-transform": "uppercase",
			"top": "550px",
			"letter-spacing": "2px",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" position="static" />
		<Box {...override("box")}>
			<Image {...override("image")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Button {...override("button")}>
				Звʼязатися з нами
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;